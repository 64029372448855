import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Grid, Box } from '@mui/material';
import PeopleIcon from '@mui/icons-material/People';
import HotelIcon from '@mui/icons-material/Hotel';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { LineChart } from '@mui/x-charts/LineChart';


//Composant pour l'onglet "Tableau de bord"

function Dashboard() {
    // États pour stocker les données de performance de l'hôtel
    const [hotelData, setHotelData] = useState([]);
    const [loading, setLoading] = useState(false);

    // États pour stocker les moyennes calculées
    const [avgOccupancyRate, setAvgOccupancyRate] = useState(0);
    const [avgRevenuePerRoom, setAvgRevenuePerRoom] = useState(0);
    const [avgRevenuePerClient, setAvgRevenuePerClient] = useState(0);

    // Fonction pour récupérer les données de l'API
    const fetchHotelData = async () => {
        setLoading(true);
        try {
            const response = await fetch('https://godatafactory.com/api/hotel-performance/');
            // const response = await fetch('http://localhost:3000/api/hotel-performance');
            if (response.ok) {
                const data = await response.json();
                setHotelData(data);
                console.log('Hotel data retrieved:', data);
            } else {
                throw new Error('Error fetching hotel performance data');
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };

    // Fonction pour calculer les moyennes
    const calculateAverages = () => {
        if (hotelData.length > 0) {
            const totalOccupancyRate = hotelData.reduce((acc, { tauxOccupChambre }) => acc + tauxOccupChambre, 0);
            const totalRevenuePerRoom = hotelData.reduce((acc, { revPrixMoyenChambre }) => acc + revPrixMoyenChambre, 0);
            const totalRevenuePerClient = hotelData.reduce((acc, { revPrixMoyenClient }) => acc + revPrixMoyenClient, 0);

            setAvgOccupancyRate(totalOccupancyRate / hotelData.length);
            setAvgRevenuePerRoom(totalRevenuePerRoom / hotelData.length);
            setAvgRevenuePerClient(totalRevenuePerClient / hotelData.length);
        }
    };

    // Utilisez useEffect pour récupérer les données au montage et calculer les moyennes
    useEffect(() => {
        fetchHotelData();
    }, []);

    useEffect(() => {
        calculateAverages();
    }, [hotelData]);


    //FONCTION POUR LES DONNEES DU PREMIER GRAPHIQUE LIGNE 
    const [chartData, setChartData] = useState([]); // État pour les données du graphique
    const [paxChartData, setPaxChartData] = useState([]); // État pour les données du graphique des pax


    // Fonction pour transformer les données de l'hôtel pour le graphique
    const prepareChartData = () => {
        const dataForChart = hotelData.map(item => ({
            date: item.date, // Assurez-vous que c'est le format de date correct
            capaciteChambre: item.capaciteChambre,
            realisationChambre: item.realisationChambre
        }));

        setChartData(dataForChart);
    };

     // Fonction pour transformer les données de l'hôtel pour le graphique des pax
     const preparePaxChartData = () => {
      const dataForChart = hotelData.map(item => ({
          date: item.date,
          capacitePax: item.capacitePax,
          realisationPax: item.realisationPax
      }));

      setPaxChartData(dataForChart);
  };

    useEffect(() => {
        if (hotelData.length > 0) {
            prepareChartData();
            preparePaxChartData();
        }
    }, [hotelData]); // Se déclenche lorsque hotelData est mis à jour


    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            {loading ? (
                <div>Loading...</div>
            ) : (
                <Grid container spacing={2} alignItems="center" justifyContent="center">
                    
                    {/* Moyenne des taux d'occupation */}
                    <Grid item xs={12} sm={4}>
                        <Card>
                            <CardContent>
                                <Typography color="textSecondary" gutterBottom>
                                    Taux d'Occupation Moyen
                                    <br />
                                    <PeopleIcon style={{ color: '#1976d2' }} />
                                </Typography>
                                <Typography  variant="h5">
                                    {avgOccupancyRate.toFixed(2)}%
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>

                    {/* Revenu moyen par chambre */}
                    <Grid item xs={12} sm={4}>
                        <Card>
                            <CardContent>
                                <Typography color="textSecondary" gutterBottom>
                                    Revenu Moyen par Chambre
                                    <br />
                                    <HotelIcon style={{ color: '#1976d2' }} />
                                </Typography>
                                <Typography variant="h5">
                                    {avgRevenuePerRoom.toFixed(2)} €
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>

                    {/* Revenu moyen par client */}
                    <Grid item xs={12} sm={4}>
                        <Card>
                            <CardContent>
                                <Typography color="textSecondary" gutterBottom>
                                    Revenu Moyen par Client
                                    <br />
                                    <AttachMoneyIcon style={{ color: '#1976d2' }} />
                                </Typography>
                                <Typography variant="h5">
                                    {avgRevenuePerClient.toFixed(2)} €
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <Typography color="textSecondary" gutterBottom>
                                    Capacité et Réalisation des Chambres par Jour
                                </Typography>
                                <Box sx={{ height: 300, maxWidth: '100%', mx: 'auto', overflow: 'hidden' }}>
                                    <LineChart
                                   
                                        series={[
                                            {
                                                curve: "linear",
                                                data: chartData.map((item) => item.capaciteChambre),
                                                label: 'Capacité Chambre',
                                                color: '#1976d2'
                                            },
                                            {
                                                curve: "linear",
                                                data: chartData.map((item) => item.realisationChambre),
                                                label: 'Réalisation Chambre',
                                                color: '#82ca9d'
                                            }
                                        ]}
                                        xAxis={[{ scaleType: 'point', data: chartData.map((item) => item.date) }]}
                                    />
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={12}>
                        <Card>
                            <CardContent>
                                <Typography color="textSecondary" gutterBottom>
                                    Capacité et Réalisation des Pax par Jour
                                </Typography>
                                <Box sx={{ height: 300, maxWidth: '100%', mx: 'auto', overflow: 'hidden' }}>
                                    <LineChart
                                        
                                        series={[
                                            {
                                                curve: "linear",
                                                data: paxChartData.map((item) => item.capacitePax),
                                                label: 'Capacité Pax',
                                                color: '#1976d2'
                                            },
                                            {
                                                curve: "linear",
                                                data: paxChartData.map((item) => item.realisationPax),
                                                label: 'Réalisation Pax',
                                                color: '#82ca9d'
                                            }
                                        ]}
                                        xAxis={[{ scaleType: 'point', data: paxChartData.map((item) => item.date) }]}
                                    />
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            )}
        </div>
    );
}

export default Dashboard;
