import React, { useEffect, useState, useMemo } from 'react';
import { Box, Grid, Button, Typography, Slider, FormControlLabel, Checkbox } from '@mui/material';
import { PieChart } from '@mui/x-charts/PieChart';
import { LineChart } from '@mui/x-charts/LineChart';
import { DataGrid } from '@mui/x-data-grid';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
// import { DateRange } from 'react-date-range';





//Composant qui affiche le "Détails" des donnes financières

function Financials() {

  //FILTRE CALENDRIER

  // Par défaut, on sélectionne de Novembre 2022 à aujourd'hui pour récupérer toutes les données

  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(2022, 10, 1), // Novembre 2022 (les mois en JS commencent à 0)
    endDate: new Date(), // Date actuelle
    key: 'selection',
  });

  // Fonction qui filtre les données financières en fonction de la sélection de DATE  
  const handleSelect = (ranges, data = financials) => {
    const { selection } = ranges;
    setSelectionRange(selection);

    // Extrait le mois et l'année de la sélection
    const startMonth = selection.startDate.getMonth() + 1;
    const startYear = selection.startDate.getFullYear();
    const endMonth = selection.endDate.getMonth() + 1;
    const endYear = selection.endDate.getFullYear();

    // Filtre les données financières
    const filtered = (data || financials).filter(item => {
      // Extrait le mois et l'année de chaque élément
      const itemMonth = parseInt(item.month.split('_')[1], 10); // Convertit "M_01" en 1
      const itemYear = item.year;

      // Crée une date pour comparer
      const itemDate = new Date(itemYear, itemMonth - 1); // Les mois en JavaScript commencent à 0
      return itemDate >= selection.startDate && itemDate <= selection.endDate;
    });

    // Met à jour l'état avec les données filtrées
    setFilteredFinancials(filtered);

  };




  //BOUTON POUR FAIRE APPARAITRE LE CALENDRIER

  // État pour afficher/cacher le calendrier
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  // Toggle l'affichage du calendrier
  const toggleCalendar = () => {
    setIsCalendarOpen(!isCalendarOpen);
  };





  //RECUPERATION DES DONNEES BRUTES DE L'API

  //stocker les données de l'API dans un state à chaque refraichissement de la page
  const [financials, setFinancials] = useState([]);

  //EN COURS : État pour indiquer le chargement des données (A utiliser pour afficher un indicateur de chargement)
  const [isLoading, setIsLoading] = useState(false);

  //fonction qui récupère les données via l'API en fonction du type de données demandées (charges, produits, trésorerie, créances, dettes)
  const getFinancials = (type) => {
    console.log('type getfinancials', type);
    console.log('json', JSON.stringify({ type }))

    setIsLoading(true); // Set loading true pour indiquer le chargement des données
    // return fetch('http://localhost:3000/api/charges', {
      return fetch('https://godatafactory.com/api/charges/', {
      method: "POST",
      body: JSON.stringify({ type }), // Utiliser le paramètre type ici

      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Something went wrong');
        }
      })
      .then(data => {
        setFinancials(data);
        handleSelect({ selection: selectionRange }, data); // Appliquer le filtre actuel aux nouvelles données
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      })
      .finally(() => {
        setIsLoading(false); // Set loading false après le chargement des données
      });
  };



  //Permet de récupérer les données de l'API au chargement de la page et évite d'avoir des graphiques vides
  //Par défaut, on récupère les données de toutes les charges
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getFinancials([
          'Achats',
          'transports',
          'services_exterieurs',
          'immpot_taxes',
          'autres_charges',
          'charge_personnel',
          'charges_frais_financiers',
          'dotations_amortissements'
        ]); // Pas besoin de .then() ici, await s'en charge
        handleSelect({ selection: selectionRange }, data); // Passez directement les données à handleSelect
      } catch (error) {
        console.error('Erreur lors de la récupération des données financières:', error);
      }
    };

    fetchData();
  }, []); // Ne s'exécute qu'au montage

  // Mettre à jour les données filtrées lorsque les données brutes changent
  useEffect(() => {
    if (financials.length > 0) {
      handleSelect({ selection: selectionRange }, financials);
    }
  }, [financials, selectionRange]); // Ajouter selectionRange aux dépendances pour réagir également aux changements de date


  //Fonction pour les boutons Charges, Produits, Trésorerie, etc qui permet de parametrer la requete de l'API et de récupérer les données correspondantes
  const handleButtonClick = (type) => {
    getFinancials(type); // Appelez getFinancials avec le type spécifié
  };




  //DONNEES FILTREEES

  // État pour stocker les données filtrées
  const [filteredFinancials, setFilteredFinancials] = useState([]);

  //DONNEES EN FORME POUR GRAPHIQUE
  // Préparez les données pour le graphique en camembert par charge_name
  const dataForChargeName = useMemo(() => {
    const totals = {}; // Pour stocker les totaux par charge_name
    filteredFinancials.forEach(item => {
      totals[item.charge_name] = (totals[item.charge_name] || 0) + item.amount;
    });
    return Object.entries(totals).map(([label, value]) => ({ label, value }));
  }, [filteredFinancials]);

  //TEST POUR GRPAHIQUE CAMEMBERT
  const renderCustomizedLabel = ({
    cx, cy, midAngle, innerRadius, outerRadius, percent, index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * Math.PI / 180);
    const y = cy + radius * Math.sin(-midAngle * Math.PI / 180);
  
    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  // Préparez les données pour le graphique en camembert par charge_category
  const dataForChargeCategory = useMemo(() => {
    const totals = {}; // Pour stocker les totaux par charge_category
    filteredFinancials.forEach(item => {
      totals[item.charge_category] = (totals[item.charge_category] || 0) + item.amount;
    });
    return Object.entries(totals).map(([label, value]) => ({ label, value }));
  }, [filteredFinancials]);

  // États pour les contrôles du premier graphique (charge_name)
  const [itemNbCharge, setItemNbCharge] = useState(5);
  const [radiusCharge, setRadiusCharge] = useState(100);
  const [skipAnimationCharge, setSkipAnimationCharge] = useState(false);
  const [showLegend1, setShowLegend1] = useState(true); // La légende est visible par défaut

  // États pour les contrôles du second graphique (charge_category)
  const [itemNbCategory, setItemNbCategory] = useState(5);
  const [radiusCategory, setRadiusCategory] = useState(100);
  const [skipAnimationCategory, setSkipAnimationCategory] = useState(false);
  const [showLegend2, setShowLegend2] = useState(true); // La légende est visible par défaut






  //Préaparer les données pour le graphique en ligne

  // Fonction pour formater la clé mois_année en label lisible
  const formatMonthYearLabel = (monthYear) => {
    const [month, year] = monthYear.split('_');
    const monthMap = { 'M_01': 'Jan', 'M_02': 'Fev', 'M_03': 'Mar', 'M_04': 'Avr', 'M_05': 'Mai', 'M_06': 'Juin', 'M_07': 'Juil', 'M_08': 'Aout', 'M_09': 'Sept', 'M_10': 'Oct', 'M_11': 'Nov', 'M_12': 'Dec' };
    return `${monthMap[month] || month} ${year}`;
  };

  const monthMapInverse = {
    'Jan': 0, 'Fev': 1, 'Mar': 2, 'Avr': 3, 'Mai': 4, 'Juin': 5, 'Juil': 6, 'Aout': 7, 'Sept': 8, 'Oct': 9, 'Nov': 10, 'Dec': 11
  };


  const dataForLineChart = useMemo(() => {
    const totalsByMonthAndYear = {}; // Stocker les totaux par mois et année

    filteredFinancials.forEach(item => {
      const monthYearKey = `${item.month}_${item.year}`; // Combinez mois et année pour créer une clé unique

      // Accumuler les totaux pour chaque mois et année
      totalsByMonthAndYear[monthYearKey] = (totalsByMonthAndYear[monthYearKey] || 0) + item.amount;
    });

    // Convertir les totaux en tableau de données pour le LineChart
    let chartData = Object.entries(totalsByMonthAndYear).map(([monthYear, total]) => ({
      label: formatMonthYearLabel(monthYear), // Formatez pour un affichage lisible
      value: total,
    }));

    // Trier les données par date (mois/année)
    chartData.sort((a, b) => {
      const [aMonthLabel, aYear] = a.label.split(' ');
      const [bMonthLabel, bYear] = b.label.split(' ');
      const aDate = new Date(aYear, monthMapInverse[aMonthLabel]);
      const bDate = new Date(bYear, monthMapInverse[bMonthLabel]);
      return aDate - bDate;
    });

    return chartData;
  }, [filteredFinancials]);


  //Creation du datagrid
  const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    {
      field: 'month',
      headerName: 'Mois',
      width: 120,
    },
    {
      field: 'year',
      headerName: 'Année',
      width: 120,
    },
    {
      field: 'charge_name',
      headerName: 'Nom',
      width: 180,
    },
    {
      field: 'charge_category',
      headerName: 'Catégorie',
      width: 180,
    },
    {
      field: 'amount',
      headerName: 'Montant',
      type: 'number',
      width: 130,
    },

  ];



  return (
    <Box>
      {/* div qui contient le filtre */}
      <Box width={'100%'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>

        {/* Box vide à gauche pour équilibrer la mise en page */}
        <Box flex={1}></Box>

        {/* Box pour les boutons du milieu */}
        <Box flex={1} display={'flex'} justifyContent={'center'} p={'1rem'}>
          <Button variant="contained" sx={{ mx: 1 }} onClick={() => handleButtonClick([
            'Achats',
            'transports',
            'services_exterieurs',
            'immpot_taxes',
            'autres_charges',
            'charge_personnel',
            'charges_frais_financiers',
            'dotations_amortissements'
          ])}>Charges</Button>
          {/* A REMPLACER PAR DES TOGGLES BUTTONS DANS L AVENIR */}
          <Button variant="contained" sx={{ mx: 1 }} onClick={() => handleButtonClick(['ventes'])}>Produits</Button>
          <Button variant="contained" sx={{ mx: 1 }} onClick={() => handleButtonClick(['tresorerie'])}>Trésorerie</Button>
          <Button variant="contained" sx={{ mx: 1 }} onClick={() => handleButtonClick(['creances'])}>Créances</Button>
          <Button variant="contained" sx={{ mx: 1 }} onClick={() => handleButtonClick(['dettes_commerciales', 'dettes_fiscales_sociales'])}>Dettes</Button>
        </Box>


        {/* Box pour le bouton "Sélectionner les dates" à droite */}
        {/* <Box flex={1} display={'flex'} justifyContent={'flex-end'} p={3}>
          <Button variant="contained" color="primary" onClick={toggleCalendar}>
            Sélectionner les dates
          </Button>
          {isCalendarOpen && (
            <Box position="absolute" zIndex="modal" mt={10}> */}
              {/* <DateRange ranges={[selectionRange]} onChange={handleSelect} /> */}
            {/* </Box>
          )}
        </Box> */}
      </Box>



      {/* div qui contient les graphiques */}
      <Box sx={{ width: '100%', px: 2 }}> {/* Ajouter un padding horizontal pour l'espace global */}
        <Grid container spacing={10} justifyContent="center">  {/* Augmenter l'espacement entre les éléments Grid */}

          {/* Premier graphique et ses contrôles */}
          <Grid item xs={12} sm={6} lg={12}> {/* Ajuster la taille selon la largeur de l'écran */}
            <Box sx={{ p: 2, border: '1px solid #eee', borderRadius: '8px' }}> {/* Box pour chaque graphique pour le contrôle du style */}
              <Typography variant="h6">Répartition par Type</Typography>
              <Box sx={{ height: 300, mb: 2, display: "flex" }}> {/* Conteneur pour le graphique avec hauteur fixe */}
                <PieChart
                  key={JSON.stringify(dataForChargeName)} // Forcer le graphique à se mettre à jour lorsque les données changent
                  series={[{ data: dataForChargeName.slice(0, itemNbCharge), outerRadius: radiusCharge }]}
                  slotProps={{ legend: { hidden: !showLegend1 } }} // Contrôler la visibilité de la légende basée sur l'état showLegend
                  skipAnimation={skipAnimationCharge}
                  label={renderCustomizedLabel}
                />

              </Box>
              <Box sx={{ maxWidth: 300 }}> {/* Réduire la largeur des sliders */}
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showLegend1}
                      onChange={() => setShowLegend1(!showLegend1)} // Basculer la visibilité de la légende
                    />
                  }
                  label="Afficher la légende"
                />

                <Typography gutterBottom>Nombre: {itemNbCharge}</Typography>
                <Slider
                  value={itemNbCharge}
                  onChange={(event, newValue) => setItemNbCharge(newValue)}
                  valueLabelDisplay="auto"
                  min={1}
                  max={dataForChargeName.length}
                />
                <Typography gutterBottom>Taille: {radiusCharge}</Typography>
                <Slider
                  value={radiusCharge}
                  onChange={(event, newValue) => setRadiusCharge(newValue)}
                  valueLabelDisplay="auto"
                  min={15}
                  max={100}
                />
              </Box>
            </Box>
          </Grid>

          {/* Second graphique et ses contrôles */}
          <Grid item xs={12} sm={6} lg={12}> {/* Ajuster la taille selon la largeur de l'écran */}
            <Box sx={{ p: 2, border: '1px solid #eee', borderRadius: '8px' }}> {/* Box pour chaque graphique pour le contrôle du style */}
              <Typography variant="h6">Répartition par Catégorie</Typography>
              <Box sx={{ height: 300, mb: 2 }}> {/* Conteneur pour le graphique avec hauteur fixe */}
                <PieChart

                  series={[{ data: dataForChargeCategory.slice(0, itemNbCategory), outerRadius: radiusCategory }]}
                  slotProps={{ legend: { hidden: !showLegend2 } }} // Contrôler la visibilité de la légende basée sur l'état showLegend
                  skipAnimation={skipAnimationCategory}
                />
              </Box>
              <Box sx={{ maxWidth: 300 }}> {/* Réduire la largeur des sliders */}
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showLegend2}
                      onChange={() => setShowLegend2(!showLegend2)} // Basculer la visibilité de la légende
                    />
                  }
                  label="Afficher la légende"
                />

                <Typography gutterBottom>Nombre de Catégories: {itemNbCategory}</Typography>
                <Slider
                  value={itemNbCategory}
                  onChange={(event, newValue) => setItemNbCategory(newValue)}
                  valueLabelDisplay="auto"
                  min={1}
                  max={dataForChargeCategory.length}
                />
                <Typography gutterBottom>Taille: {radiusCategory}</Typography>
                <Slider
                  value={radiusCategory}
                  onChange={(event, newValue) => setRadiusCategory(newValue)}
                  valueLabelDisplay="auto"
                  min={15}
                  max={100}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} lg={12}>
            <Box sx={{ p: 2, border: '1px solid #eee', borderRadius: '8px', textAlign: 'center' }}>
              <Typography variant="h6">Évolution par Mois</Typography>
              <Box sx={{ height: 300, maxWidth: '100%', mx: 'auto', overflow: 'hidden' }}>
                {console.log('dataForLineChart', dataForLineChart)}
                <LineChart

                  series={[{
                    curve: "linear",
                    data: dataForLineChart.map(item => item.value), // Extraire uniquement les valeurs pour la série de données
                    label: 'Dépenses',

                    showMark: true,
                    markStyle: {
                      display: 'auto', // Assurez-vous que cette propriété est disponible et fonctionnelle dans votre version de MUI X Charts
                    }
                  }]}
                  xAxis={[{
                    scaleType: 'point',
                    data: dataForLineChart.map(item => item.label), // Utiliser les labels pour l'axe X
                  }]}
                  sx={{
                    '.MuiLineElement-root': {

                    },
                  }}
                />

              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} lg={12}>
            <Box sx={{ p: 2, borderRadius: '8px', textAlign: 'center' }}>
              <Typography p={1} variant="h6">Détails</Typography>
              <Box sx={{ height: 500, maxWidth: '100%', mx: 'auto', overflow: 'hidden' }}>
                <DataGrid
                  rows={filteredFinancials.map((item, index) => ({ ...item, id: index + 1 }))}
                  columns={columns.map(column => ({ ...column, flex: 1 }))} // Utiliser 'flex' pour un ajustement dynamique
                  initialState={{
                    pagination: { paginationModel: { pageSize: 25 } },
                  }}
                  pageSizeOptions={[5, 10, 25]}
                />


              </Box>
            </Box>
          </Grid>

        </Grid>
      </Box>
    </Box>
  );
}




export default Financials;
